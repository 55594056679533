

// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #619E00;

$body-bg:  #e6e7ee;

$font-family-sans-serif: "Work Sans", sans-serif;

html,body {
    height: 100%;
    width: 100%;
}

@import "../node_modules/bootstrap/scss/bootstrap";

.neo {
    box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff !important;
}

.shadow-inset {
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
}

.neo-btn {
    position: relative;
    transition: all .2s ease;
    letter-spacing: .025em;
    font-size: 1rem;
    background-color: #e6e7ee;
    border-color: #d1d9e6;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;

    &:hover {
        color: #31344b;
         background-color: #e6e7ee;
        border-color: #e6e7ee;
         box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
    }
}

.neo-btn-primary {
    background-color: #A8EB12;
    border-color: #A8EB12;

    &:hover {
        background-color: #A8EB12;
        border-color: #A8EB12; 
        box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #A8EB12;
    }
}
/* START Anchor Styles */

a {
    text-decoration: none;

    &.underline {
        display: inline-block;
        position: relative;

        &:hover {
            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 3px;
            bottom: 0;
            left: 0;
            background-color: #F55555;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }
    }
}
/* START Resume styles */

.sidebar-wrapper {
    .tagline {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
    }

    .contact-list {
        .svg-inline--fa {
            margin-right: 5px;
            font-size: 18px;
            vertical-align: middle;
        }

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .email {
            .svg-inline--fa {
                font-size: 14px;
            }
        }
    }

    .container-block {
        padding: 30px;
    }

    .container-block-title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .degree {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .education-container {
        .item {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .meta {
            font-weight: 500;
            margin-bottom: 0px;
            margin-top: 0;
            font-size: 14px;
        }

        .time {
            font-weight: 500;
            margin-bottom: 0px;
        }
    }

    .languages-container {
        .lang-desc {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .languages-list {
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .interests-list {
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.main-wrapper {
    .section-title {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-top: 0;
        margin-bottom: 20px;
        display: flex;

        .icon-holder {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            display: flex;
            color: #fff;
            border-radius: 50%;
            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            text-align: center;
            font-size: 16px;
            position: relative;
            top: -2px;
            padding-top: 2px;
            justify-content: center;
            align-items: center;

            .svg-inline--fa {
                font-size: 14px;
                margin-top: 6px;
            }
        }
    }

    .section {
        margin-bottom: 60px;
    }

    .experiences-section {
        .item {
            margin-bottom: 30px;
        }
    }

    .upper-row {
        position: relative;
        overflow: hidden;
        margin-bottom: 2px;
    }

    .job-title {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 500;
    }

    .time {
        position: absolute;
        right: 0;
        top: 0;
    }

    .company {
        margin-bottom: 10px;
    }

    .project-title {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 500;
    }

    .projects-section {
        .intro {
            margin-bottom: 30px;
        }

        .item {
            margin-bottom: 15px;
        }
    }
}

/* END Resume styles */

//Social 

.linkedin {
    color: #0a66c2 !important;
}

.github {
    color: #6e5494 !important;
}

.mail {
    color: #c71610 !important;
}

// skills 

.react, .react-native {
    color:  #61DBFB;
}

.js {
    color: #ffd43b;
}

.html5 {
    color: #e34f26;
}

.css3 {
    color: #264de4;
}

.sass {
    color: #c69;
}

.node-js {
    color: #3c873a;
}

.python {
    color: #4584b6;
}

.vue {
    color: #42b883;
}

.git {
    color: #F1502F;
}

footer {
    small {
        font-size: x-small;
    }
}


.page {
    flex-direction: 'row';
    background-color: '#E4E4E4',
  }